import { Chip } from '@mui/material';
import { GridToolbar } from "@mui/x-data-grid";

const capitaliseFirstLetter = (string) => String(string).charAt(0).toUpperCase() + String(string).slice(1);

export const faultCols = [
    {
        field: "severity", headerName: "Severity", minWidth: 100, flex: 1, renderCell: (params) => (
            <Chip label={capitaliseFirstLetter(params.value)} size="small" variant="outlined" color={params.value === "warning" ? "warning" : params.value === "info" ? "info" : "error"} />
        )
    },
    { field: "site", headerName: "Site", minWidth: 140, flex: 1 },
    { field: "building", headerName: "Building", minWidth: 110, flex: 1 },
    { field: "floor", headerName: "Floor", minWidth: 110, flex: 1 },
    { field: "door", headerName: "Door", minWidth: 120, flex: 1 },
    { field: "fault", headerName: "Fault", minWidth: 130, flex: 1 },
    { field: "since", headerName: "Since", minWidth: 160, flex: 1 },
];

export const deviceCols = [
    {
        field: "has_fault", headerName: "Status", minWidth: 80, flex: 1, renderCell: (params) => (
            <Chip label={params.value === false ? "OK" : "Fault"} size="small" variant="outlined" color={params.value === false ? "success" : "warning"} />
        )
    },
    { field: "site", headerName: "Site", minWidth: 140, flex: 1 },
    { field: "building", headerName: "Building", minWidth: 110, flex: 1 },
    { field: "floor", headerName: "Floor", minWidth: 110, flex: 1 },
    { field: "door", headerName: "Door", minWidth: 120, flex: 1 },
    {
        field: "door_state", headerName: "Door state", flex: 1, minWidth: 100, renderCell: (params) => (
            <Chip label={capitaliseFirstLetter(params.value)} size="small" variant="outlined" color={params.value === "closed" ? "success" : "warning"} />
        )
    },
    { 
        field: "battery", headerName: "Battery", type: "number", minWidth: 90, flex: 1, renderCell: (params) => (
            params.value !== null ? `${params.value > 100 ? 100 : params.value}%` : "Unknown"
        )
    },
    { field: "temperature", headerName: "Temperature", type: "number", minWidth: 110, flex: 1, renderCell: (params) => (params.value !== null ? `${params.value}°C` : "Unknown")},
    { field: "humidity", headerName: "Humidity", type: "number", minWidth: 100, flex: 1, renderCell: (params) => (params.value !== null ? `${params.value}%` : "Unknown")},
    { field: "opened_total", headerName: "Times opened", type: "number", minWidth: 110, flex: 1, renderCell: (params) => (params.value !== null ? `${params.value}` : "Unknown")},
    { field: "last_seen", headerName: "Last seen", minWidth: 160, flex: 1 },
    { field: "site_id", headerName: "Site ID" },
];

export const slots = {
    toolbar: GridToolbar,
};